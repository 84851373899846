import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export class CloudVendorsApiService {
  static ApiService: CCApiService;
  static cloudVendorsApi: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.cloudVendorsApi = `${this.apiBasePath}/cloudvendors`;
  }

  static async getCloudVendors(): Promise<any> {
    return this.ApiService.performFetch(this.cloudVendorsApi).then(res => res);
  }

  static async getCloudVendorRegions(vendorId: string): Promise<any> {
    return this.ApiService.performFetch(`${this.cloudVendorsApi}/${vendorId}/regions`).then(res => res);
  }
}